<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">
                Document Management<br />
              </h1>
              <h3 class="subtitle has-text-light-grey is-size-5">
                Document management solutions that optimises performance and
                efficiency
              </h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="feature-content columns">
      <div class="column is-size-4">
        <h1>
          Our approach to document management is different. We look at document
          management from the perspective of the
          <span>entire organisation</span> and then break this down to explore
          it from the perspective of <span> individual users. </span>
          <br /><br />
          Individual perspective is crucial and often overlooked in document
          management projects, resulting in <span>low engagement</span> with
          established document management systems and processes, ultimately
          leading to a <span>reduced level of compliance</span> and
          <span>standardisation</span> within the organisation. <br /><br />
          Our goal is to <span>seamlessly</span> integrate document management
          capabilities into our client's workflows to allow users to perform
          their document management duties <span>efficiently</span> and
          <span>accurately</span>. <br /><br />
          We deliver dedicated document management solutions as well as
          integrate existing <span>DMS</span> and <span>EDRMS</span> platforms
          into our client's systems, resulting in
          <span>significant improvements</span> from both an
          <span>individual user</span> and an
          <span>organisation-wide perspective</span>.
        </h1>
      </div>
    </section>
  </div>
</template>

<style scoped>
@import "../../styles/feature-content.scss";
.hero-body {
  background: url("/img/solutions-banner/document-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
}
#hero-home {
  position: relative;
}
#hero-home:after {
  content: "\A";
  background-image: url("/img/solutions-banner/document-banner.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}
</style>

<script>
export default {
  name: "Document Management"
}
</script>
